<template lang="pug">
section.section.entry-screen.container
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .columns(v-if="!initializing")
    .column.is-12.header-flex
      h1.title Export
      .export-currency-select(:class="{'has-subunits': hasSubunits}")
        currency-select.base-currency(:value="chosenCurrency" @input="changeCurrency" placeholder="Convert to" prefix="Exporting in")
        subunit-select.subunits(v-if="hasSubunits" v-model="chosenSubunit" :currency="chosenCurrency.id")
  .columns(v-if="!initializing")
    .column.is-12
      .box
        .content
          strong How to export your data:
          ol
            li Make sure you've chosen the currency you want
            li (optional) Choose the date range you want to export
            li Click export and wait for it to complete
            li Your browser will prompt you to save a spreadsheet file containing your data
        b-field(label="Start date (optional)")
          b-datepicker(v-model="startDate" :date-parser="dateParser" :date-formatter="dateFormatterLong" :max-date="today")
        b-field(label="End date (optional)")
          b-datepicker(v-model="endDate" :date-parser="dateParser" :date-formatter="dateFormatterLong" :max-date="today")
        b-button(type="is-large is-primary" :loading="exporting" @click="startExport") Export to XLSX
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from '@/dayjs'
import {
  dateParser,
  dateFormatterLong,
  currencyWithSubunit
} from '@/misc/helpers'
import CurrencySelect from '@/components/CurrencySelect'
import SubunitSelect from '@/components/SubunitSelect'
import zipcelx from '@/misc/async-zipcelx'

export default {
  name: 'ExportView',
  components: {
    CurrencySelect,
    SubunitSelect
  },
  data() {
    const today = dayjs().toDate()
    return {
      today,
      exporting: false,
      startDate: null,
      endDate: dayjs(today).toDate(),
      chosenCurrency: null,
      chosenSubunit: null
    }
  },
  computed: {
    ...mapState('currencies', ['loadingCurrencies', 'activeCurrency']),
    ...mapState('entries', ['entries', 'loadingEntries']),
    ...mapState('categories', ['categories', 'loadingCategories']),
    ...mapGetters('authentication', ['isFreePlan']),
    ...mapGetters('currencies', [
      'getCurrencyById',
      'activeSubunit',
      'defaultSubunit'
    ]),
    initializing() {
      return (
        this.loadingCurrencies || this.loadingEntries || this.loadingCategories
      )
    },
    hasSubunits() {
      const { chosenCurrency } = this
      return chosenCurrency && 'subunits' in chosenCurrency
    }
  },
  watch: {
    initializing: {
      immediate: true,
      handler(notDone) {
        if (notDone) {
          return
        }
        if (this.isFreePlan) {
          this.chosenCurrency = this.getCurrencyById('USD')
          this.chosenSubunit = null
        } else {
          this.chosenCurrency = this.activeCurrency
          this.chosenSubunit = this.activeSubunit ? this.activeSubunit.id : null
        }
      }
    }
  },
  mounted() {
    // Preload xlsx export lib
    zipcelx()
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    ...mapActions('entries', ['exportToXLSX']),
    async startExport() {
      try {
        this.exporting = true
        await this.exportToXLSX({
          startDate: this.startDate,
          endDate: this.endDate,
          currency: currencyWithSubunit(
            this.chosenCurrency.id,
            this.chosenSubunit
          )
        })
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'An error occurred',
          message: error.message
        })
      }
      this.exporting = false
    },
    changeCurrency(currency) {
      if (this.isFreePlan) {
        this.$buefy.dialog.confirm({
          title: 'Free plan limitation',
          message:
            'Our starter plan only allows you to export your holdings in USD. To unlock additional export options, you will need to upgrade your plan.',
          cancelText: 'Export in USD',
          confirmText: 'Choose a Plan',
          onConfirm: () => {
            this.$router.push({ name: 'billing_plan' })
          }
        })
        return
      }
      this.chosenCurrency = currency
      this.chosenSubunit = this.defaultSubunit(currency.id)
    },
    dateParser,
    dateFormatterLong
  },
  metaInfo: {
    title: 'Export Data'
  }
}
</script>
<style lang="sass" scoped>
.header-flex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  .title
    flex-grow: 1
    white-space: nowrap
    margin-right: 2rem
  .control .button
    height: 38px
  .dropdown .button
    border-left: 1px solid darkgreen
.export-currency-select
  display: flex
  flex-direction: row
.has-subunits
  .multiselect
    ::v-deep
      .multiselect__content-wrapper
        width: 300px
  .base-currency
    ::v-deep .multiselect__tags
      border-radius: 5px 0 0 5px
      border-right-width: 1px
  .subunits
    ::v-deep
      select
        border: 1px solid #e8e8e8
        border-left-width: 0
        border-radius: 0 5px 5px 0
  ::v-deep .multiselect__single
    white-space: nowrap
@media screen and (max-width: 600px)
  .header-flex
    .title
      width: auto
      order: 0
</style>
